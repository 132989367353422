// Ongoing Events Container
//
// This component is used to display the ongoing events in the main page.
//
// Get the data from the API and display it in a table.
//

import React, { useState, useEffect } from "react";
import axios from "axios";
import { Row, Col, Alert } from "react-bootstrap";

import { useEffectOnce } from "../../functions/useEffectOnce";

function AlertsContainer() {
    const [alertsFeed, setAlertsFeed] = useState([]);
    const [alertsFeedLoaded, setAlertsFeedLoaded] = useState(false);
    const [isLoaded, setIsLoaded] = useState(false);

    // Get the Alerts Feed
    useEffectOnce(() => {
        axios
            .get("https://algs.tas.gg/api/alerts")
            .then((response) => {
                setAlertsFeed(response.data);
            })
            .catch((error) => {
                console.log(error);
                setAlertsFeed([]);
            })
            .finally(() => {
                setAlertsFeedLoaded(true);
            });
    });

    // Check the latest alerts every minute
    useEffectOnce(() => {
        const timer = setInterval(() => {
            axios
                .get("https://algs.tas.gg/api/alerts")
                .then((response) => {
                    setAlertsFeed(response.data);
                })
                .catch((error) => {
                    console.log(error);
                    setAlertsFeed([]);
                })
                .finally(() => {
                    setAlertsFeedLoaded(true);
                });
        }, 60000);
        // clearing interval
        return () => clearInterval(timer);
    });

    // Check if the data is loaded
    useEffect(() => {
        if (alertsFeedLoaded === true) {
            setIsLoaded(true);
        }
    }, [alertsFeedLoaded]);

    if (isLoaded && alertsFeed.length > 0) {
        return (
            <Row>
                <Col className="col-12">
                    <Alert
                        variant="danger"
                        className="text-white text-center"
                    >
                        <span class="fw-bold h4">Notices</span>
                        <br />
                        <div className="w-100 d-flex flex-column justify-content-center align-items-center">
                            {alertsFeed.map((alert, index) => {
                                return (
                                    <li
                                        key={alert.id}
                                        className="fs-6"
                                    >
                                        {alert.message}
                                    </li>
                                );
                            })}
                        </div>
                    </Alert>
                </Col>
            </Row>
        );
    } else {
        return null;
    }
}

export default AlertsContainer;
