import React, { useState } from "react";
import axios from "axios";
import { Spinner, Card, Tab, Tabs } from "react-bootstrap";
import { useEffectOnce } from "../../functions/useEffectOnce";
import ALGSAllMatchesMatchListing from "./ALGSAllMatchesMatchListing";
import { useMatomo } from "@datapunt/matomo-tracker-react";

import AlertsContainer from "../feeds/AlertsContainer";

function ALGSAllMatchesView(props) {
    const { trackEvent } = useMatomo();
    const [isLoaded, setIsLoaded] = useState(false);
    const [viewData, setViewData] = useState();

    const [leagueKey, setLeagueKey] = useState({});
    const [splitKey, setSplitKey] = useState({});
    const [regionKey, setRegionKey] = useState({});

    // Load all the ALGS data on page initial load
    useEffectOnce(() => {
        axios.get("https://algs.tas.gg/api/feeds/all").then((response) => {
            setViewData(response.data);
            setIsLoaded(true);

            // Set the league key to the first league
            setLeagueKey(Object.keys(response.data.leagues)[0]);

            // Set the split key to the first split of each league
            let splitKeyTemp = {};
            Object.keys(response.data.leagues).forEach((league) => {
                splitKeyTemp[league] = league + "-" + Object.keys(response.data.leagues[league].events)[0];
            });
            setSplitKey(splitKeyTemp);

            // Set the region key to the first region of each split of each league
            let regionKeyTemp = {};
            Object.keys(response.data.leagues).forEach((league) => {
                Object.keys(response.data.leagues[league].events).forEach((split) => {
                    // if the league has not been created yet, create it
                    if (regionKeyTemp[league] === undefined) {
                        regionKeyTemp[league] = {};
                    }
                    regionKeyTemp[league][split] =
                        league +
                        "-" +
                        split +
                        "-" +
                        Object.keys(response.data.leagues[league].events[split].sections)[0];
                });
            });
            setRegionKey(regionKeyTemp);

            // Set the league to props.league if props.isLeagueSet is true
            if (props.isLeagueSet === true) {
                setLeagueKey(props.league);
            }

            // Set the split to props.split if props.isSplitSet is true and props.league is set
            if (props.isSplitSet === true && props.isLeagueSet === true) {
                splitKeyTemp[props.league] = props.league + "-" + props.split;
                setSplitKey(splitKeyTemp);
            }

            // Set the region to props.region if props.isRegionSet is true and props.league and props.split are set
            if (props.isRegionSet === true && props.isLeagueSet === true && props.isSplitSet === true) {
                regionKeyTemp[props.league][props.split] = props.league + "-" + props.split + "-" + props.region;
                setRegionKey(regionKeyTemp);
            }
        });
    });

    // Load all the ALGS data every minute
    useEffectOnce(() => {
        const timer = setInterval(() => {
            axios.get("https://algs.tas.gg/api/feeds/all").then((response) => {
                setViewData(response.data);
                setIsLoaded(true);
            });
        }, 60000);
        // clearing interval
        return () => clearInterval(timer);
    });

    return (
        <>
            <Card className="mt-3 mb-3 bg-dark">
                <Card.Header>
                    <h2 className="text-center">ALGS Matches</h2>
                </Card.Header>
                <Card.Body>
                    <center className="fs-6">
                        To get started, select the match you are looking for by selecting the league, split, and region.
                        Once you have selected a match you will be presented with some options to personalize the
                        command to your stream. The command to add to your chat will be displayed at the bottom of the
                        page, categorized by Twitch bot.
                        <br />
                        <br />
                        <AlertsContainer />
                        <b className="fs-5 text-warning">
                            Qualifiers and Challenger Circuit matches will be available as soon as the brackets are
                            generated and released.
                        </b>
                    </center>
                    {isLoaded === true ? (
                        <>
                            <Tabs
                                id="nav-leagues"
                                defaultActiveKey={leagueKey}
                                onSelect={(k) => {
                                    trackEvent({
                                        category: "All ALGS Matches",
                                        action: "Select League",
                                        name: "Selected " + viewData.leagues[k].name,
                                    });
                                    setLeagueKey(k);
                                    window.history.replaceState(
                                        null,
                                        "ALGS - Tournaments - Twitch Apex Stats",
                                        "/algs/" +
                                            k +
                                            "/" +
                                            splitKey[k].replace(k + "-", "") +
                                            "/" +
                                            regionKey[k][splitKey[k].replace(k + "-", "")].replace(
                                                k + "-" + splitKey[k].replace(k + "-", "") + "-",
                                                ""
                                            )
                                    );
                                }}
                                variant="pills"
                                className="bg-body pt-1 pb-1 border-start border-top border-end border-secondary rounded-top"
                            >
                                <Tab
                                    key={"league-all"}
                                    title="League"
                                    disabled
                                    tabClassName="ps-2 pe-2 ms-0 me-1"
                                ></Tab>
                                {Object.keys(viewData.leagues).map((league) => (
                                    <Tab
                                        eventKey={league}
                                        key={league}
                                        title={viewData.leagues[league].name}
                                        tabClassName="fw-bold pt-2 pb-2 ps-3 pe-3 ms-0 me-1"
                                    >
                                        <Tabs
                                            id={"nav-" + league + "-splits"}
                                            key={"nav-" + league + "-splits"}
                                            defaultActiveKey={splitKey[league]}
                                            onSelect={(k) => {
                                                trackEvent({
                                                    category: "All ALGS Matches",
                                                    action: "Select Split",
                                                    name:
                                                        "Selected " +
                                                        viewData.leagues[league].name +
                                                        ": " +
                                                        viewData.leagues[league].events[k.replace(league + "-", "")]
                                                            .name,
                                                });
                                                let tempKey = splitKey;
                                                tempKey[league] = k;
                                                setSplitKey(tempKey);
                                                window.history.replaceState(
                                                    null,
                                                    "ALGS - Tournaments - Twitch Apex Stats",
                                                    "/algs/" +
                                                        league +
                                                        "/" +
                                                        k.replace(league + "-", "") +
                                                        "/" +
                                                        regionKey[league][k.replace(league + "-", "")].replace(
                                                            league + "-" + k.replace(league + "-", "") + "-",
                                                            ""
                                                        )
                                                );
                                            }}
                                            variant="pills"
                                            className="pt-1 pb-1 border-start border-end border-secondary"
                                        >
                                            <Tab
                                                key={league + "-all"}
                                                title="Split"
                                                disabled
                                                tabClassName="ps-2 pe-2 ms-0 me-1"
                                            ></Tab>
                                            {Object.keys(viewData.leagues[league].events).map((split) => (
                                                <Tab
                                                    eventKey={league + "-" + split}
                                                    key={league + "-" + split}
                                                    title={viewData.leagues[league].events[split].name}
                                                    tabClassName="fw-bold pt-2 pb-2 ps-2 pe-2 ms-0 me-1"
                                                >
                                                    <Tabs
                                                        id={"nav-" + league + "-" + split + "-regions"}
                                                        defaultActiveKey={regionKey[league][split]}
                                                        onSelect={(k) => {
                                                            trackEvent({
                                                                category: "All ALGS Matches",
                                                                action: "Select Region",
                                                                name:
                                                                    "Selected " +
                                                                    viewData.leagues[league].name +
                                                                    ": " +
                                                                    viewData.leagues[league].events[split].name +
                                                                    ": " +
                                                                    viewData.leagues[league].events[split].sections[
                                                                        k.replace(league + "-" + split + "-", "")
                                                                    ].name,
                                                            });
                                                            let tempKey = regionKey;
                                                            tempKey[league][split] = k;
                                                            setRegionKey(tempKey);
                                                            window.history.replaceState(
                                                                null,
                                                                "ALGS - Tournaments - Twitch Apex Stats",
                                                                "/algs/" +
                                                                    league +
                                                                    "/" +
                                                                    split +
                                                                    "/" +
                                                                    k.replace(league + "-" + split + "-", "")
                                                            );
                                                        }}
                                                        variant="pills"
                                                        className="bg-body pt-1 pb-1 border-start border-bottom border-end border-secondary rounded-bottom"
                                                    >
                                                        <Tab
                                                            eventKey={league + "-" + split + "-all"}
                                                            title="Region"
                                                            disabled
                                                            tabClassName="ps-2 pe-2 ms-0 me-1"
                                                        ></Tab>
                                                        {Object.keys(
                                                            viewData.leagues[league].events[split].sections
                                                        ).map((region) => (
                                                            <Tab
                                                                eventKey={league + "-" + split + "-" + region}
                                                                key={league + "-" + split + "-" + region}
                                                                title={
                                                                    viewData.leagues[league].events[split].sections[
                                                                        region
                                                                    ].name
                                                                }
                                                                // Set tabClassName to bg-danger if not active
                                                                tabClassName="fw-bold pt-2 pb-2 ps-1 pe-1 ms-0 me-1"
                                                            >
                                                                <ALGSAllMatchesMatchListing
                                                                    key={
                                                                        league + "-" + split + "-" + region + "-matches"
                                                                    }
                                                                    league={league}
                                                                    split={split}
                                                                    region={region}
                                                                    matches={
                                                                        viewData.leagues[league].events[split].sections[
                                                                            region
                                                                        ].matches
                                                                    }
                                                                />
                                                            </Tab>
                                                        ))}
                                                    </Tabs>
                                                </Tab>
                                            ))}
                                        </Tabs>
                                    </Tab>
                                ))}
                            </Tabs>
                        </>
                    ) : (
                        <div className="text-center col-auto mx-auto">
                            <Spinner
                                animation="border"
                                variant="primary"
                                style={{
                                    width: "3rem",
                                    height: "3rem",
                                }}
                            />
                        </div>
                    )}
                </Card.Body>
            </Card>
        </>
    );
}

export default ALGSAllMatchesView;
