import React from "react";
import { useMatomo } from "@datapunt/matomo-tracker-react";
import { TrophyFill, ArrowDown } from "react-bootstrap-icons";
import { Row, Col, Card } from "react-bootstrap";
// Removed currently: import { Alert } from "react-bootstrap";
import { Twitter, Discord, Twitch } from "react-bootstrap-icons";
import { Link } from "react-router-dom";

import { useEffectOnce } from "../functions/useEffectOnce";

import UpcomingTournamentsContainer from "../components/feeds/UpcomingTournamentsContainer";
import OngoingTournamentsContainer from "../components/feeds/OngoingTournamentsContainer";
import AlertsContainer from "../components/feeds/AlertsContainer";

function PageMain(props) {
    const { trackPageView } = useMatomo();

    useEffectOnce(() => {
        document.title = "Tournaments - Twitch Apex Stats";
        trackPageView();
    });

    // Scroll to top of page
    useEffectOnce(() => {
        window.scrollTo(0, 0);
    });

    return (
        <>
            <h1 className="text-center">
                <TrophyFill /> Tournaments
            </h1>
            <Row className="mb-3">
                <Col xs="12">
                    <Card>
                        <Card.Body>
                            <center>
                                Quickly add a command to your Twitch chat to show up to date standings for tournaments.{" "}
                                <b>ALGS</b> and other <b>Battlefy</b> tournaments are supported, as well as tournaments
                                tracked using{" "}
                                <a
                                    className="fw-bold"
                                    href="https://apexlegendsstatus.com/custom-games"
                                    target="_alscgs"
                                >
                                    Apex Legend Status' Custom Games Stats
                                </a>
                                . {/*Select events ran by Oversight Esports are available as well.*/} Once added, every
                                time your viewers use the command in chat they get the latest standings, with no need
                                for moderators to update the command as the standings change. Simple to add and free to
                                use. Great for competitors and watch party streamers!
                                <br />
                                <br />
                                <div
                                    className="bg-black border border-white text-white text-start font-monospace p-1"
                                    style={{ maxWidth: "300px", fontSize: "0.8rem" }}
                                >
                                    NA: Day 1 - A vs B - November 6th, 2022 --- TSM 64, AP 60, TL 53, COL 44, DZ 44, G2
                                    39, NRG 38, ESA 36, SMILE 33, TRI 32, BRD 31, FUR 28, MPS 25, CLG 25, BOTS 21, E8
                                    20, C9 16, RAK 16, SZN 16, SUP 8 --- GAME 6 COMPLETE of 6
                                </div>
                                <span className="text-muted">Example of the command output</span>
                                <br />
                                <br />
                                <center className="fs-4 fw-bold">Get Started!</center>
                                <a
                                    href="#ot"
                                    className="btn btn-primary btn-block fw-bold fs-6 m-1"
                                >
                                    ALGS/Battlefy Tournaments
                                    <span style={{ fontSize: "0.7rem" }}>
                                        <br />
                                        <ArrowDown /> See Below <ArrowDown />
                                    </span>
                                </a>
                                <Link to="/als">
                                    <button className="btn btn-primary btn-block fw-bold fs-6 m-1">
                                        Apex Legends Status
                                        <span style={{ fontSize: "0.82rem" }}>
                                            <br />
                                            Custom Game Stats
                                        </span>
                                    </button>
                                </Link>
                                <Link to="/als-dgs">
                                    <button className="btn btn-primary btn-block fw-bold fs-6 m-1">
                                        Apex Legends Status
                                        <span style={{ fontSize: "0.82rem" }}>
                                            <br />
                                            Tournament Detailed Game Stats
                                        </span>
                                    </button>
                                </Link>
                            </center>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
            <AlertsContainer />
            <br />
            <OngoingTournamentsContainer />
            <br />
            <UpcomingTournamentsContainer />
            <Row className="mt-3">
                <Col xs="12">
                    <Card>
                        <Card.Body>
                            <Card.Title className="text-center h3">Created By</Card.Title>
                            <Card.Text>
                                <b>Twitch Apex Stats</b> and related tools are created by Casey Blackburn, a technology
                                consultant and software developer that enjoys playing Apex Legends as well as being a
                                part of the community.
                            </Card.Text>
                            <div className="text-center">
                                <a
                                    href="https://twitter.com/MrCaseyJames"
                                    target="_blank"
                                    rel="noreferrer"
                                    className="btn btn-primary m-2 btn-sm"
                                >
                                    <Twitter /> @MrCaseyJames
                                </a>
                                <a
                                    href="https://discordapp.com/users/471792768503513088"
                                    target="_blank"
                                    rel="noreferrer"
                                    className="btn btn-secondary m-2 btn-sm"
                                >
                                    <Discord /> @mrcaseyjames
                                </a>
                                <a
                                    href="https://twitch.tv/caseyblackburn"
                                    target="_blank"
                                    rel="noreferrer"
                                    className="btn btn-secondary m-2 btn-sm"
                                >
                                    <Twitch /> CaseyBlackburn
                                </a>
                            </div>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
        </>
    );
}

export default PageMain;
